.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;
  color: var(--black);
}

.button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
}

.button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}

@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
}
