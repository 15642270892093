.social-media-div {
  font-size: 2em;
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: #ffffff;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin: 0 5px 10px;
  transition: 0.2s ease-in;
}

.facebook i {
  background-color: #3b5998;
}

.linkedin i {
  background-color: #0e76a8;
}

.github i {
  background-color: #333;
}

.google i {
  background-color: #ea4335;
}

.twitter i {
  background-color: #1da1f2;
}

.twitter i {
  background-color: #1da1f2;
}

.kaggle i {
  background-color: #20beff;
}

.instagram i {
  background-color: #c13584;
}

/* Hover Transition */
.twitter i:hover,
.google i:hover,
.github i:hover,
.linkedin i:hover,
.facebook i:hover,
.instagram i:hover,
.kaggle i:hover,
.twitter i:hover {
  background-color: #000000;
}

@media screen and (max-width: 480px) {
  .social-media-div {
    text-align: center;
    margin-bottom: var(--space-between-components-responsive);
  }
  .icon-button i {
    height: 4rem;
    width: 4rem;
    line-height: 4rem;
    font-size: 2.2rem;
  }
}
