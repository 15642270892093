.contact-form {
  display: flex;
  height: 80vh;
  margin-top: var(--space-between-components);
  padding: 3rem 3rem 0;
}

.c-right {
  display: flex;
  justify-content: center;
  position: relative;
  flex: 1;
}

.c-right > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.c-right .user {
  width: 20rem;
  height: 2rem;
  padding: 0.3em;
  outline: none;
  border: 2px solid var(--orange);
  border-radius: 8px;
  font-size: 16px;
}

textarea {
  height: 4rem !important;
}

.c-blur1 {
  top: 1rem;
  left: 8rem;
}

@media screen and (max-width: 480px) {
  .contact-form {
    padding: 0;
    flex-direction: column;
    gap: 5rem;
    height: 40rem;
  }

  .c-right .user {
    width: 16rem;
  }
}
