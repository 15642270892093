.footer {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 -3.5rem -0.5rem;
}

.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10rem;
  gap: 4rem;
  color: white;
}

.f-icons {
  display: flex;
  gap: 2rem;
}

.span-footer {
  color: #000;
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .footer {
    margin: 0 -3.5rem -0.5rem;
  }
  .f-content {
    transform: scale(0.5);
  }
  .span-footer {
    font-size: 22px;
  }
}
